"use client";
import React from "react";
import * as RadixToast from "@radix-ui/react-toast";
import Icon from "./icon";

function Toast(props: RadixToast.ToastProviderProps) {
  return (
    <RadixToast.Provider
    {...props}
      duration={props.duration || 5000}
      swipeDirection="right"
    >
      {props.children}
    </RadixToast.Provider>
  );
}

function Content(
  props: RadixToast.ToastProps & React.RefAttributes<HTMLLIElement>
) {
  return (
    <>
      <RadixToast.Root
        {...props}
        className="relative bg-white rounded-md shadow-elevation-300 focus:shadow-elevation-400 items-center data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut"
      >
        {props.children}
        <RadixToast.Close className="absolute top-3 right-3 fill-dark">
          <Icon name="close" size="sm" />
        </RadixToast.Close>
      </RadixToast.Root>
      <RadixToast.Viewport className="[--viewport-padding:_25px] fixed bottom-0 md:top-[var(--navbar-height)] right-0 flex flex-col p-[var(--viewport-padding)] gap-[10px] w-[390px] max-w-[100vw] m-0 list-none z-[2147483647] outline-none" />
    </>
  );
}

export function ToastProvider(props: RadixToast.ToastProviderProps) {
  return (
    <RadixToast.Provider {...props}>{props.children}</RadixToast.Provider>
  )
}

Toast.Content = Content;
export default Toast;
