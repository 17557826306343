import React from "react";
import { useSearchParams, usePathname } from "next/navigation";

export const useParamUpdater = () => {
  const pathname = usePathname();

  const createParamString = React.useCallback(
    (value: string, index: number) => {
      let segments = pathname.split("/").filter((segment) => segment);
      if (segments.length >= index) {
        segments[index] = value;
        return `/${segments.join("/")}`;
      }
    },
    [pathname]
  );

  return { createParamString };
};

export const useQueryUpdater = () => {
    const pathname = usePathname();
    const searchParams = useSearchParams()!;
  
    const removeQueryParam = React.useCallback(
      (paramToRemove: string) => {
        const params = new URLSearchParams(searchParams.toString());
        params.delete(paramToRemove);
        const final = `${pathname}?${params.toString()}`;
        return params.toString() ? final : pathname;
      },
      [searchParams, pathname]
    );
  
    const createQueryParamString = React.useCallback(
      (name: string, value: string) => {
        const paramsString = searchParams.toString();
        const params = new URLSearchParams(paramsString);
        params.set(name, value);
  
        return `${pathname}?${params.toString()}`;
      },
      [searchParams]
    );
  
    return { createQueryParamString, removeQueryParam };
  };
  