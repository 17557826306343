"use client";
import Icon from "@/components/primitives/icon";
import Info from "@/components/primitives/info";
import Button from "../primitives/button";
import Form from "../primitives/form";
import Link from "next/link";
import { useUser } from "@clerk/nextjs";
import { useRouter } from "next-nprogress-bar";
import { AuthContext } from "@/app/(public)/drawer";
import { useContext } from "react";

export default function AccountConfirmationComponent() {
  const router = useRouter();
  const { setAuth, closeAuth } = useContext(AuthContext);

  const { isSignedIn, user, isLoaded } = useUser();

  if (!isLoaded || !isSignedIn) {
    return null;
  }

  return (
    <>
      {/* <div className="flex justify-start items-center pb-16">
           <Button
            variant="circle"
            color="tertiary"
            onClick={() => closeAuth()}
          >
            <Icon name="close" className="fill-secondary-300" size="sm" />
          </Button>
        </div> */}
      <div className="flex flex-col gap-6 justify-center items-center text-center w-full max-w-md">
        <div className="flex flex-col gap-10">
          <Info.Block
            title="Welcome to Moneyline!"
            variant="success"
            confetti
            // message="We've sent you an email confirmation. Click on the link in the email to verify your Moneyline account."
            message=""
          />

          <Form.Submit onClick={() => setAuth(null)} variant="filled">
            Start Picking
          </Form.Submit>
          {/* <div className="gap w-full text-center">
              <p className="text-sm text-secondary-500 gap-text">or</p>
            </div> */}
        </div>
        {/* <Link
            href={removeQueryParam("auth")}
            prefetch={false}
            className="text-title-medium uppercase"
          >
            Do this Later
          </Link> */}
      </div>
    </>
  );
}
