import React from "react";
import type { SVGProps } from "react";

export default function Loading(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle cx={18} cy={12} r={0} fill="currentColor">
        <animate
          attributeName="r"
          begin={0.67}
          calcMode="spline"
          dur="1.5s"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
          repeatCount="indefinite"
          values="0;2;0;0"
        ></animate>
      </circle>
      <circle cx={12} cy={12} r={0} fill="currentColor">
        <animate
          attributeName="r"
          begin={0.33}
          calcMode="spline"
          dur="1.5s"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
          repeatCount="indefinite"
          values="0;2;0;0"
        ></animate>
      </circle>
      <circle cx={6} cy={12} r={0} fill="currentColor">
        <animate
          attributeName="r"
          begin={0}
          calcMode="spline"
          dur="1.5s"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
          repeatCount="indefinite"
          values="0;2;0;0"
        ></animate>
      </circle>
    </svg>
  );
}

export function LoadingCircle({ size, stroke }: {size?: number, stroke?: number}) {
  return (
    <svg
      width={`${size || "64"}`}
      height={`${size || "64"}`}
      viewBox="0 0 83 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80.4922 41.9981C80.4922 50.459 77.7406 58.6905 72.6526 65.4507C67.5646 72.2109 60.4162 77.1331 52.2857 79.4747C44.1552 81.8163 35.4837 81.4504 27.5795 78.4321C19.6752 75.4137 12.9669 69.9067 8.46654 62.7419C3.96621 55.577 1.91798 47.143 2.63086 38.7121C3.34374 30.2812 6.77905 22.3108 12.4185 16.0034C18.058 9.69591 25.5958 5.39351 33.8947 3.74524C42.1935 2.09697 50.8033 3.19224 58.4252 6.86582"
        stroke="var(--primary)"
        strokeWidth={`${stroke || "5"}`}
        strokeLinecap="round"
        className="animate-spin origin-center"
      />
      {/* add svg animate element to make the above path rotate indefinitely */}
    </svg>
  );
}
