"use client";
import * as RadixCheckbox from "@radix-ui/react-checkbox";
import Icon from "./icon";

export interface CheckboxProps extends RadixCheckbox.CheckboxProps {
  id: string;
  label: string;
}

export default function Checkbox(props: CheckboxProps) {
  return (
    <>
      <div className="flex gap-2 items-center">
        <RadixCheckbox.Root
          {...props}
          className="hover:bg-surface-600 flex shrink-0 h-5 w-5 appearance-none items-center justify-center rounded-sm border-[2px] border-secondary-500 soutline-none data-[state=checked]:bg-primary data-[state=checked]:border-primary"
          defaultChecked
          id={props.id}
        >
          <RadixCheckbox.Indicator>
            <Icon name="checkmark" size="sm" color="dark" />
          </RadixCheckbox.Indicator>
        </RadixCheckbox.Root>
        <label className="text-secondary-500 text-label md:text-sm" htmlFor={props.id}>
          {props.label}
        </label>
      </div>
    </>
  );
}
