// TODO: REMOVE - DEPRECATED

/**
 * Utils for debugging values.
 */

import { EmptyState, formatEmptyState, isEmpty } from "common";

/**
 * inspects a value, logging it to console when debugging.
 * @returns a formatted empty state where applicable.
 */
export function inspect<T>(
  value: T,
  empty: EmptyState = "n/a",
  metaData?: string
): string | T {
  if (process.env.DEBUG === "true") {
    console.log(`Inspecting value ${metaData ? `for ${metaData}` : ""}`, value);
  }
  if (isEmpty(value)) {
    return formatEmptyState(empty);
  }
  return value;
}
