"use client";
import React from "react";
import * as Dialog from "@radix-ui/react-dialog";

type SheetSide = "top" | "left" | "right" | "bottom";
type SheetVariance = {
  [key in SheetSide]: string;
};
const sheetVariance: SheetVariance = {
  top: "top-0 inset-x-0 w-full h-3/4 max-h-sm border-t data-[state=closed]:animate-slideOutUp data-[state=open]:animate-slideInDown",
  bottom:
    "bottom-0 inset-x-0 w-full h-3/4 max-h-sm border-t data-[state=closed]:animate-slideOutDown data-[state=open]:animate-slideInUp",
  left: "inset-y-0 left-0 h-full w-3/4 max-w-sm border-r data-[state=closed]:animate-slideOutLeft data-[state=open]:animate-slideInRight",
  right: `inset-y-0 right-0 h-full w-full max-w-lg border-l data-[state=closed]:animate-slideOutRight data-[state=open]:animate-slideInLeft`,
};

interface SheetProps extends Dialog.DialogProps {
  trigger?: React.ReactNode;
  side?: SheetSide;
  onClickOverlay?: (e?: React.MouseEvent<HTMLDivElement>) => void;
}
function Sheet(props: SheetProps) {
  const classes: string = `fixed z-[51] bg-white shadow-elevation-400 transition-all outline-none ${
    sheetVariance[props.side || "right"]
  }`;
  return (
    <Dialog.Root {...props}>
      <Dialog.Trigger asChild>{props.trigger}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Content className={classes} style={{ pointerEvents: "auto" }}>
          {props.children}
        </Dialog.Content>

        <div
          onClick={(e) => {
            if (props.onClickOverlay) {
              if (e?.target === e?.currentTarget) {
                props.onClickOverlay(e);
              }
            }
          }}
          className="pointer-events-auto bg-white/75 data-[state=open]:animate-overlayShow transition-all data-[state=closed]:animate-overlayHide fixed inset-0 backdrop-blur-sm z-50 max-[512px]:hidden"
        ></div>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default Sheet;
