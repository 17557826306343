"use client";
import React from "react";
import cn from "classnames";
import SelectPrimitive, { SelectProps } from "./select";
import Button, { BUTTON_VARIANCE } from "./button";
import * as RadixForm from "@radix-ui/react-form";
import Icon from "./icon";

function Form(props: RadixForm.FormProps) {
  return <RadixForm.Root {...props}>{props.children}</RadixForm.Root>;
}

function Field(props: RadixForm.FormFieldProps) {
  return (
    <>
      <RadixForm.Field
        {...props}
        className={cn(`group flex flex-col relative`, props.className)}
      >
        {props.children}
      </RadixForm.Field>
    </>
  );
}

interface ExtendedFormControlProps extends RadixForm.FormControlProps {
  ref?: React.RefObject<any | null>;
  dark?: boolean;
}

const Input = React.forwardRef(
  (props: ExtendedFormControlProps, forwardedRef) => {
    if (!props.dark) {

      return (
        <>
        <RadixForm.Control {...props} asChild>
          <input
            ref={forwardedRef as React.RefObject<HTMLInputElement> | null}
            className={`group w-full border text-input text-dark py-3 px-4 rounded-md h-[3.25rem] flex justify-between items-center outline-none placeholder:text-secondary border-input-grey data-[valid=true]:border-success-300 data-[invalid=true]:border-error-300 hover:border-dark focus:border-dark transition-all duration-100 ease-in-out ${props.className}`}
          />
        </RadixForm.Control>
      </>
    );
  }
  return (
    <>
    <RadixForm.Control {...props} asChild>
          <input
            ref={forwardedRef as React.RefObject<HTMLInputElement> | null}
            className={`group w-full border text-input text-white py-3 px-4 rounded-md h-[3.25rem] flex justify-between items-center outline-none placeholder:text-secondary border-secondary-700 data-[valid=true]:border-success-300 data-[invalid=true]:border-error-300 hover:border-white focus:border-white transition-all duration-100 ease-in-out bg-dark ${props.className}`}
          />
        </RadixForm.Control></>
  );
  }
);

function PasswordInput(props: RadixForm.FormControlProps) {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  return (
    <>
      <div className="relative">
        <RadixForm.Control {...props} asChild>
          <input
            type={showPassword ? "text" : "password"}
            className={`group w-full border text-input text-dark py-3 px-4 rounded-md h-[3.25rem] flex justify-between items-center outline-none placeholder:text-secondary border-input-grey data-[valid=true]:border-success-300 data-[invalid=true]:border-error-300 hover:border-dark focus:border-dark transition-all duration-100 ease-in-out pr-4 ${props.className}`}
          />
        </RadixForm.Control>
        <button
          type="button"
          tabIndex={-1}
          onClick={(e) => {
            setShowPassword(!showPassword);
          }}
          className="absolute right-4 top-[14px]"
        >
          {showPassword ? (
            <Icon name="view" className="fill-secondary-500" size="lg" />
          ) : (
            <Icon name="view-off" className="fill-secondary-500" size="lg" />
          )}
        </button>
      </div>
    </>
  );
}

function Message(props: RadixForm.FormMessageProps) {
  return (
    <RadixForm.Message
      {...props}
      className={cn(
        `${
          props.match === "valid" ? "text-success-300" : "text-error-300"
        } text-label-lg mt-1`,
        props.className
      )}
    >
      {props.children}
    </RadixForm.Message>
  );
}

function Select(props: SelectProps & RadixForm.FormControlProps) {
  return (
    <RadixForm.Control asChild>
      <SelectPrimitive {...props} variant="form" />
    </RadixForm.Control>
  );
}

interface SubmitProps {
  variant: "filled" | "outlined";
}

function Submit(props: SubmitProps & RadixForm.FormSubmitProps) {
  return (
    <RadixForm.Submit {...props} className="btn btn-md btn-filled-primary w-full">
        {props.children}
    </RadixForm.Submit>
  );
}

function Label(props: RadixForm.FormLabelProps & { static?: boolean }) {
  const visibilityClasses: string = props.static
    ? "opacity-100 visible"
    : "opacity-0 invisible group-focus-within:opacity-100 group-focus-within:visible";
  return (
    <RadixForm.Label
      className={`text-label text-secondary-300 bg-white py-0.5 px-1 absolute left-2 -top-2 z-[1] rounded-sm ${visibilityClasses}`}
    >
      {props.children}
    </RadixForm.Label>
  );
}

Form.Field = Field;
Form.Input = Input;
Form.PasswordInput = PasswordInput;
Form.Message = Message;
Form.Select = Select;
Form.Submit = Submit;
Form.Label = Label;
export default Form;
