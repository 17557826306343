import { HTMLAttributes } from "react";
import Icon from "./icon";
import Button from "./button";

export interface InfoBlockProps extends HTMLAttributes<HTMLDivElement> {
  variant?: "default" | "success" | "error" | "loading";
  confetti?: boolean;
  icon?: string;
  title: string;
  message: string;
}

function Block(props: InfoBlockProps) {
  const variant = props.variant || "default";
  if (variant !== "default") {
    return (
      <div className="flex flex-col gap-4 justify-center items-center">
        <div
          className={`relative inline-flex justify-center items-center rounded-full p-5 ${
            props.variant === "success" ? "bg-success-700" : "bg-error-700"
          }`}
        >
          {props.confetti && props.variant === "success" && (
            <Icon
              name="confetti"
              className="absolute w-[9.5rem] h-[7rem] -bottom-3 fill-primary-600"
            />
          )}
          <Icon
            name={
              props.icon ||
              (props.variant === "success" ? "checkmark" : "alert-triangle")
            }
            className={
              props.variant === "success"
                ? "fill-success-300 color-success-300"
                : "fill-error-300 color-error-300"
            }
            size="xl"
          />
        </div>
        <div className="text-display-xl uppercase text-dark">{props.title}</div>
        <div className="text-base text-secondary">{props.message}</div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col justify-center items-center">
        <div
          className={`relative inline-flex justify-center items-center rounded-full p-8 bg-dark`}
        >
          {props.icon && (
            <Icon name={props.icon} className="fill-primary" size="2xl" />
          )}
        </div>
        <div className="text-headline-bold uppercase text-dark mt-10">
          {props.title}
        </div>
        <div className="text-base text-secondary mt-4">{props.message}</div>
      </div>
    );
  }
}

export interface InfoInlineProps extends HTMLAttributes<HTMLDivElement> {
  variant: "success" | "error";
  icon?: string;
  message: string;
  filled?: boolean;
  action?: ActionProps;
}

function Inline(props: InfoInlineProps) {
  return (
    <div
      className={`flex flex-row w-full gap-2 justify-between items-center rounded-md px-4 py-3 flex-wrap min-w-[12rem] ${
        props.filled &&
        (props.variant === "success" ? "bg-success-700" : "bg-error-700")
      }`}
    >
      <div className="flex flex-row gap-4 justify-start items-center">
        <Icon
          name={props.variant === "success" ? "checkmark" : "alert-triangle"}
          className={
            props.variant === "success"
              ? "fill-success-300 color-success-300"
              : "fill-error-300 color-error-300"
          }
          size="sm"
        />
        <div className="text-label-lg-medium text-secondary-200">
          {props.message}
        </div>
      </div>
      {props.action && (
        <div className="flex justify-center items-center">
          <Button
            href={props.action.href}
            onClick={props.action.onClick}
            variant="info"
          >
            {props.action.label}
          </Button>
        </div>
      )}
    </div>
  );
}

const Info = { Block, Inline };

export default Info;
